





















































































































































































.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-item {
    width: 45%;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.addClassCtr .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.addClassCtr .el-form .form-item {
  width: 45%;
}
.addClassCtr .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
